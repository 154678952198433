import { useTranslation } from "react-i18next"
import { useScale } from "../../hooks/useScale.hook"
import { animated } from '@react-spring/web'

export const Point: React.FC<{name: string, left: number, top: number}> = ({name, left, top}) => {
    const { t } = useTranslation()
    const [ , scaleStyle ] = useScale(100)

    const [ , ms ] = useScale(0)
    return <div style={{position: 'absolute', top, left}}>
        <animated.div style={{width: 53, height: 53, background: 'rgba(64,250,226,0.15)', borderRadius: '50%', ...scaleStyle}}></animated.div>
        <animated.div style={{width: 22, height: 22, background: 'rgba(64,250,226,0.5)', borderRadius: '50%', position: 'absolute', zIndex: 1, top: 15, left: 15, ...ms}}></animated.div>
        <animated.div style={{width: 10, height: 10, background: '#40FAE2', borderRadius: '50%', position: 'absolute', zIndex: 1, top: 21, left: 21}}></animated.div>
        <div style={{fontWeight: 500, fontSize: 20, color: '#FFFFFF', position: 'absolute', top: -8, left: '50%', transform: `translateX(-50%)`, whiteSpace: 'nowrap'}}>{t(name)}</div>
    </div>
}