import { Banner } from './Banner';
import { TechnologyBlock } from './TechnologyBlock'
import { Technology } from '../Technology';
import { TestReport } from './TestReport';
import { City } from './City';
import { Partner } from './Partner';
import { Footer } from './Footer';
import { MenuColorProvider } from '../hooks/useMenuColor.hook';
import { Header } from './Header';
import {
  createHashRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { Contact } from '../Contact';
import { News } from '../News';
import { Service } from '../Service';
import { AboutUs } from '../AboutUs';
import { CityV2 } from './CityV2';


const PageContainer = () => <MenuColorProvider>
  <Header />
  <Outlet />
  <Footer />
</MenuColorProvider>


const router = createHashRouter([
  {
    path: '/',
    element: <PageContainer />,
    children: [
      {
        path: '/',
        element: <>
          <Banner />
          <TechnologyBlock />
          <TestReport />
          <CityV2 />
          {/* <City /> */}
          <Partner />
        </>
      }, {
        path: '/contact',
        element: <Contact />
      }, {
        path: '/news',
        element: <News />
      }, {
        path: '/service',
        element: <Service />
      }, {
        path: '/technology',
        element: <Technology />
      }, {
        path: 'about-us',
        element: <AboutUs />
      }
    ]
  }
])

// const router = createHashRouter([
//   {
//     path: '/',
//     element: <>
//       <Banner />
//       <Technology />
//       <TestReport />
//       <City />
//       <Partner />
//     </>
//   }, {
//     path: '/contact',
//     element: <Contact />
//   }, {
//     path: '/news',
//     element: <News />
//   }
// ])

export const Index = () => {
  return (
    <RouterProvider router={router} />
  );
}
