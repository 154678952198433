import { useTranslation } from 'react-i18next'
import { useMenuColor } from '../../hooks/useMenuColor.hook'
import css from './index.module.scss'
import map from './map.png'
import { Point } from './point'
import { useMemo, useState } from 'react'
import { useInView } from '@react-spring/web'
import { useInterval } from 'ahooks'

const data = [
    {name: '北京', top: 291, left: 702},
    {name: '唐山', top: 298, left: 758},
    {name: '天津', top: 334, left: 741},
    {name: '银川', top: 363, left: 559},
    {name: '阳泉', top: 372, left: 659},
    {name: '青岛', top: 395, left: 793},
    {name: '兰州', top: 427, left: 495},
    {name: '西安', top: 457, left: 603},
    {name: '许昌', top: 470, left: 684},
    {name: '合肥', top: 485, left: 745},
    {name: '无锡', top: 472, left: 791},
    {name: '上海', top: 488, left: 856},
    {name: '苏州', top: 514, left: 792},
    {name: '芜湖', top: 529, left: 760},
    {name: '杭州', top: 540, left: 807},
    {name: '武汉', top: 533, left: 685},
    {name: '成都', top: 559, left: 505},
    {name: '重庆', top: 568, left: 568},
    {name: '长沙', top: 609, left: 663},
    {name: '福州', top: 633, left: 795},
    {name: '厦门', top: 672, left: 770},
    {name: '广州', top: 677, left: 670},
    {name: '深圳', top: 696, left: 719},
    {name: '珠海', top: 713, left: 678},
    {name: '香港', top: 727, left: 726},
    {name: '琼海', top: 779, left: 641},
]

export const CityV2 = () => {
    const ref = useMenuColor({
        color: '#fff',
        background: '#222'
    })
    const [count, setCount] = useState(0)

    const [mapRef, inView] = useInView({once: true, rootMargin: '-50%'})

    useInterval(() => {
        inView && setCount(count+1)
    }, 100)

    const arr = useMemo(() => data.slice(0, count), [count])
    return <div className={css['city-v2']} ref={ref}>
        <div className={css['img-wrap']} ref={mapRef}>
            <img src={map} className={css['img']}/>
            {arr.map(item => <Point name={item.name} left={item.left} top={item.top}/>)}
        </div>
    </div>
}